<template>
  <v-container column>
      <v-responsive class="text-center fill-height">
        <v-container>
          <v-layout  align-center justify-center row wrap>
            <v-flex xs12 sm12 md6 lg6
                    class="pa-3"
                    v-for="option in listingOptions"
                    :key="option.title"
            >
              <v-card
                @click="goTolisting (option)"
              >
                <v-img
                    v-if="option.titleColour === 'black'"
                    :src="option.imgUrl"
                    class="black--text align-center"
                    height="200px"
                >
<!--                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"-->
                  <v-card-title v-text="option.title"></v-card-title>

                </v-img>
                <v-img
                    v-if="option.titleColour === 'white'"
                    :src="option.imgUrl"
                    class="white--text align-center"
                    height="200px"
                >
                  <!--                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"-->
                  <v-card-title v-text="option.title"></v-card-title>

                </v-img>
              </v-card>

            </v-flex>
          </v-layout>
        </v-container>
      </v-responsive>
    <div class="hidden-sm-and-up" style="height: 150px"></div>
  </v-container>
</template>
<script>
import {collection, onSnapshot} from 'firebase/firestore'
export default {
  data() {
    return {
      listingOptions: null
    }
  },
  created() {
    let db = this.$store.state.db
    let listingsRef = collection(db, 'pageContent', 'troopermovesMain', 'listingOptions')
    onSnapshot(listingsRef, res => {
      if (!res.empty) {
        let listingOptions = []
        res.docs.forEach(doc => {
          listingOptions.push(doc.data())
        })
        if (listingOptions.length !== 0) {
          this.listingOptions = listingOptions
        }
      }
    })
  },
  methods: {
    goTolisting (listingOption) {
      this.$gtag.event('viewedListing', { method: 'Google' })
      // this.$ma.trackEvent({category: 'Click', action: 'initEstimate'})
      window.open(listingOption.listingUrl)
    }
  }
}
</script>
