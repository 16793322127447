<template>
  <v-container class="fill-height">
    <v-responsive class="text-center fill-height">
      <v-container fluid>
        <!--send text message-->
        <v-flex xs12 sm6 offset-sm3>
          <v-card-title>Natural Language Processing Bot</v-card-title>
          <v-textarea
              outlined
              label="New Text"
              v-model="textMessage"
          ></v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  @click="sendText" class="blue white--text">send</v-btn>
          </v-card-actions>
        </v-flex>
        <!--messages-->
        <v-expansion-panels v-if="selectedConvos.length !== 0">
          <v-expansion-panel
              v-for="(item,i) in selectedConvos"
              :key="i"
          >
            <v-expansion-panel-header>
              <div v-if="item.direction === 'incoming'">
                <v-icon class="success--text">call_received</v-icon>
              </div>
              <div v-if="item.direction === 'outgoing'">
                <v-icon class="blue--text">call_made</v-icon>
              </div>
              <v-container>
                <div>{{item.text}}</div>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!--auto detect date and time-->
              <v-layout v-if="item.dialogFlowAgent">
<!--                <v-btn v-if="item.dialogFlowAgent"-->
<!--                       @click="addText(item.dialogFlowAgent.response.fulfillmentText)"-->
<!--                       text outlined rounded-->
<!--                       class="black&#45;&#45;text">-->
<!--                  {{item.dialogFlowAgent.response.fulfillmentText.slice(0,8)}}</v-btn>-->
<!--                <v-card-text v-if="!loading">-->
<!--                  <v-btn-->
<!--                      :disabled="!id"-->
<!--                      v-if="item.dialogFlowAgent.response.intent.displayName === 'meetup_date'"-->
<!--                      text outlined-->
<!--                      rounded-->
<!--                      class="blue&#45;&#45;text"-->
<!--                      @click="addScheduleDate(item)"-->
<!--                  >edit <v-icon>event</v-icon></v-btn>-->
<!--                  <v-btn-->
<!--                      :disabled="!id"-->
<!--                      v-if="item.dialogFlowAgent.response.intent.displayName === 'meetup_date'"-->
<!--                      text outlined rounded-->
<!--                      class="blue&#45;&#45;text"-->
<!--                      @click="addScheduleTime(item)"-->
<!--                  >edit <v-icon>timer</v-icon></v-btn>-->
<!--                  <h6 class="text-xs-left">-->
<!--                    {{moment(item.created)}}-->
<!--                  </h6>-->
<!--                </v-card-text>-->
<!--                <app-loading v-else></app-loading>-->
              </v-layout>
              <v-layout v-if="!item.dialogFlowAgent">
                <v-card-text>
                  <v-btn
                      disabled
                      text outlined
                      rounded
                      class="blue--text">add date
                    <v-icon>event</v-icon></v-btn>
                  <v-btn
                      disabled
                      text outlined rounded
                      class="blue--text"
                  ><v-icon>timer</v-icon>Add time</v-btn>
                </v-card-text>
              </v-layout>
              <v-layout>
                <v-card-text v-if="!loading">
                  <h6 class="text-xs-left">
                    {{moment(item.created)}}
                  </h6>
                </v-card-text>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
 import * as moment from 'moment'
 import { v1 as uuidv1 } from 'uuid'
 import {collection, addDoc, onSnapshot} from 'firebase/firestore'
  export default {
    data: () => ({
      textMessage: '',
      selectedConvos: [],
      loading: false
    }),
    computed: {
      moment () {
        return (date) => {
          let day = moment(date).format('ddd, MMM Do, hh:mm:ss A')
          return day
        }      }
    },
    created () {
      let db = this.$store.state.db
      let convosRef = collection(db, 'convos')
      onSnapshot(convosRef, res => {
        if (res && res.empty !== true) {
          let convos = []
          res.forEach(doc => {
            // console.log(doc.data())
            let data = doc.data()
            convos.push(data)
          })
          this.selectedConvos = convos.sort((convoA, convoB) => {
            return convoB.created - convoA.created
          })
        }
      })
    },
    methods: {
      sendText () {
        let id = uuidv1()
        let db = this.$store.state.db
        let outgoingMsg = {
          text: this.textMessage,
          created: Date.now(),
          direction: 'outgoing',
          type: 'text'
          //  direction: 'incoming' //change to outgoing before deploying
        }
        return addDoc(collection(db, 'convos'), outgoingMsg, { merge: true })            .catch(err => {
              this.$store.dispatch('setError', err)
            })
            .then(() => {
              this.textMessage = ''
            })
      }
    }
  }
</script>
