import Vue from 'vue'
import Vuex from 'vuex'

import firebaseConfig from "@/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const app = initializeApp(firebaseConfig);

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    firebase: app,
    db: getFirestore(app),
    pageData: null
  },
  getters: {
    getPageData (state) {
      return state.pageData
    },
  },
  mutations: {
    setPageData (state, payload) {
      state.pageData = payload
    },
  },
  actions: {
    setPageData ({commit}, payload) {
      commit('setPageData', payload)
    }
  },
  modules: {
  }
})
