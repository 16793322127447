<template>
  <v-container column class="fill-height">
    <v-responsive class="text-center fill-height">
      <v-container v-if="pageData">
        <v-layout  align-center justify-center row wrap>
          <v-flex xs12 sm12 md12 lg6>
            <v-img :src="pageData.headerImage"></v-img>
          </v-flex>
          <v-flex xs12 sm12 md12 lg4>
            <h1>{{pageData.headerTitle}}</h1>
            <br />
            <h4  class="text-xs-center font-weight-regular">
            {{pageData.headerSubtitle}}
            </h4>
            <br />
            <v-btn
                rounded
                :style="pageData.buttonStyle"
                dark large
                @click="goToEstimate"
            >{{pageData.actionButtonTitle}}</v-btn
            >
          </v-flex>

          <v-card-text class="pt-9 pb-2">
            <!--steps section-->
            <v-layout v-if="pageData" align-center>
              <v-flex
                  class="text-xs-center"
                  xs12 sm12 md12 lg12
                  v-for="(step, index) in pageData.actionSteps"
                  :key = "index"
              >
                <div><v-icon large>
                 {{step.icon}}
                </v-icon></div>
                <div class="font-italic font-weight-medium">
                  {{step.title}}
                </div>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-card-text>

          <!--About Us Section-->
          <v-layout  justify-center align-center mx-5 row class="ma-5">
              <v-flex xs12 sm12 md12 lg12 class="mt-5">
                <div style="text-align: center;" class="subheading font-weight-light mt-2">
                  <div class="display-1 font-weight-light">
                    {{pageData.aboutUsTitle}}
                  </div>
                  <p>{{pageData.aboutUsSubtitle}}</p>
                </div>
              </v-flex>
            </v-layout>
          <!-- Market served Section-->
          <v-layout row wrap  justify-center>
            <v-flex xs12 sm12 md12 lg5>
              <v-container>
                <v-img :src="pageData.marketServedImg"></v-img>
              </v-container>
            </v-flex>
            <v-flex xs12 sm12 md12 lg5>
              <v-layout
                  justify-center
                  align-center
                  fill-height
                 :style="pageData.checkerBoxStyle"
                  pa-4
              >
                <v-flex  xs10 sm10 md10 lg8>
                  <h1 class="mt-12 text-xs-center white--text font-weight-thin">
                    {{pageData.marketServedTitle}}
                  </h1>
                  <h3
                      style="text-align: center;"
                      class="white--text font-weight-regular mb-12">
                    {{pageData.marketServedSubtitle}}
                  </h3>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!--service offerings Section-->
          <v-layout  justify-center
                     row wrap mb-3>
            <v-flex xs12 sm12 md12 lg5>
              <v-layout
                  justify-center
                  align-center
                  fill-height
                  :style="pageData.checkerBoxStyle"
                  pa-4
              >
                <v-flex xs10 sm10 md10 lg8>
                  <h1 class=" white--text text-xs-center font-weight-thin">
                    {{pageData.serviceOfferTitle}}
                  </h1>
                  <h3
                      style="text-align: center;"
                      class="white--text font-weight-regular mb-12">
                    {{pageData.serviceOfferSubtitle}}
                  </h3>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg5>
              <v-container>
                <v-img :src="pageData.serviceOfferImg"></v-img>
              </v-container>
            </v-flex>
          </v-layout>
<!--          blog section-->
          <v-layout column justify-center>
            <div style="height: 50px"></div>
            <v-flex xs12 sm12 md12 lg5
                    v-for="post in posts"
                    :key="post.created"
            >
              <v-card
                  class="mx-auto mb-3"
                  max-width="600"
                  outlined
              >
                <v-list-item>
                  <v-list-item-content class="mb-1 mr-2">
                    <v-card-text class="text-start">
                      <span class="text-h5">
                      <a target="_blank"
                         style="color:black; text-decoration: none;"
                         :href="post.url">
                        {{post.title}}
                      </a>
                    </span>
                    </v-card-text>

                  </v-list-item-content>
                  <v-list-item-avatar
                      tile
                      size="200"
                      color="grey"
                      class="hidden-sm-and-down"
                  >
                    <v-img
                        :src="post.featuredImg"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-avatar
                      tile
                      size="100"
                      color="grey"
                      class="hidden-md-and-up"
                  >
                    <v-img
                        :src="post.featuredImg"
                    ></v-img>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text>
                    <a target="_blank"
                       style="color:black; text-decoration: none;"
                       :href="post.url">
                      Read more..
                    </a>
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-flex>
            <v-flex xs12 sm12 md12 lg5>
              <v-btn text >
                <a target="_blank" style="color:black"
                   :href="pageData.blogUrl">
                  <v-icon class="black--text">feed</v-icon>
                  view blog
                </a>
              </v-btn>
            </v-flex>
            <div style="height: 25px"></div>
          </v-layout>

          <!--Reviews Section-->
          <v-layout class="mt-6"  justify-center align-center mx-5 row wrap>
            <v-flex>
              <v-layout align-center justify-center>
                <h1 class="font-weight-light">
                  {{pageData.reviewsTitle}}
                </h1>
              </v-layout>
              <v-divider></v-divider>
              <v-layout mt-4 justify-center>
                <v-flex xs12 sm12 md10 lg10>
                  <v-carousel
                      :show-arrows="false"
                      cycle
                      hide-delimiters
                      height="300px"
                      class="theme-review-carousel"
                      dark
                      show-arrows-on-hover
                  >
                    <v-carousel-item
                        v-for="(review, index) in pageData.reviews"
                        :key="index"
                    >
                      <v-card flat color="white">
                        <v-card-actions>
                          <v-layout>
                            <v-avatar
                                size="56"
                                class="grey lighten-3"
                            >
                              <v-icon large class="black--text">person</v-icon>
                            </v-avatar>
                            <div style="margin-left: 10px; margin-top:10px;">
                              <h4 class="black--text">{{ review.reviewedBy }}</h4>
                              <v-rating
                                  dense
                                  small
                                  :value="review.rating"
                                  readonly
                                  color="orange"
                              ></v-rating>
                            </div>
                          </v-layout>
                        </v-card-actions>
                        <v-card-text
                            class="black--text"
                            style="text-align: justify;"
                        >
                          {{ review.text }}
                        </v-card-text>
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>
                </v-flex>
              </v-layout>
              <v-btn
                  class="mb-3"
                  rounded
                  :style="pageData.buttonStyle"
                  dark large
                  @click="goToEstimate"
              >{{pageData.actionButtonTitle}}
              </v-btn>
            </v-flex>
          </v-layout>

          <v-layout style="height: 300px">
            <v-img :src="pageData.bottomBackgroundImg" contain></v-img>
          </v-layout>
        </v-layout>
      </v-container>
    </v-responsive>
  </v-container>
</template>
<script>
import {collection, doc, onSnapshot} from 'firebase/firestore'
export default {
  data() {
    return {
      pageData: null,
      posts: [],
      projects: 'sdf'
    }
  },
  created() {
    let db = this.$store.state.db
    let pageRef = doc(db, 'pageContent', 'troopermovesMain')
    let blogRef = collection(db, 'pageContent', 'troopermovesMain', 'blogs')
    onSnapshot(pageRef, res => {
      if (res.exists) {
        let data = res.data()
        this.pageData = data
        this.$store.dispatch('setPageData', data)
        // console.log(data)
      }
    })
    onSnapshot(blogRef, res => {
      if (!res.empty) {
        let posts = []
        res.docs.forEach(doc => {
          posts.push(doc.data())
        })
        if (posts.length !== 0) {
          this.posts = posts
        }
      }
    })
  },
  methods: {
    goToEstimate () {
      this.$gtag.event('actionButton', { method: 'Google' })
      // this.$ma.trackEvent({category: 'Click', action: 'initEstimate'})
      window.open(this.pageData.actionButtonLink)
    }
  }
}
</script>
