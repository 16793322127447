<template>
  <v-app>
    <!--    app bar-->
    <v-app-bar  app v-if="pageData">
      <v-toolbar-title class="mt-1 ml-3">
        <a href="/" style=" text-decoration: none;">
          <img :src="pageData.toolbarLogo" height="43px" width="auto"/></a>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-layout align-center justify-end >
        <v-btn @click="phoneDialog = true" outlined small class="mr-1">
            <v-icon>smartphone</v-icon>
            call or text
        </v-btn>
        <v-btn
            class="hidden-sm-and-down"
            text
            v-if="pageData.blogUrl !== ''"
        >
          <a target="_blank" style="color:black; text-decoration: none;"
             :href="pageData.blogUrl">
            <v-icon class="black--text">business</v-icon>
           about us
          </a>
        </v-btn>
        <v-btn
            v-if="pageData.igUrl !== ''"
            icon small
            class="hidden-sm-and-down"
        >
          <a target="_blank" style="color:white"
             :href="pageData.igUrl">
            <v-icon class="black--text">mdi-instagram</v-icon>
          </a>
        </v-btn
        >
        <v-btn
            v-if="pageData.fbUrl !== ''"
            icon
            class="hidden-sm-and-down"
        >
          <a target="_blank" style="color:white"
             :href="pageData.fbUrl">
            <v-icon class="black--text">mdi-facebook</v-icon>
          </a>
        </v-btn>
        <v-btn
            v-if="pageData.xUrl !== ''"
            icon
            class="hidden-sm-and-down"
        >
          <a target="_blank" style="color:white"
             :href="pageData.xUrl">
            <v-icon class="black--text">mdi-twitter</v-icon>
          </a>
        </v-btn
        >
        <v-btn
            v-if="pageData.linkedIn !== ''"
            icon
            class="hidden-sm-and-down">
          <a target="_blank" style="color:white"
             :href="pageData.linkedInUrl">
            <v-icon class="black--text">mdi-linkedin</v-icon>
          </a>
        </v-btn>
      </v-layout>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn class="hidden-md-and-up" icon v-on="on">
            <v-icon>menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-btn
                  v-if="pageData.blogUrl !== ''"
                  text>
                <a target="_blank" style="color:black; text-decoration: none;"
                   :href="pageData.blogUrl">
                  <v-icon class="black--text">business</v-icon>
                  About us
                </a>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn
                  v-if="pageData.igUrl !== ''"
                  text>
                <a target="_blank" style="color:black; text-decoration: none;"
                   :href="pageData.igUrl">
                  <v-icon class="black--text">mdi-instagram</v-icon>
                  Instagram
                </a>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-btn
                v-if="pageData.fbUrl !== ''"
                text>
              <a target="_blank" style="color:black; text-decoration: none;"
                 :href="pageData.fbUrl">
                <v-icon class="black--text">mdi-facebook</v-icon>
                facebook
              </a>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn
                  v-if="pageData.xUrl !== ''"
                  text>
                <a target="_blank" style="color:black; text-decoration: none;"
                   :href="pageData.xUrl">
                  <v-icon class="black--text">mdi-twitter</v-icon>
                  X
                </a>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn
                  v-if="pageData.linkedIn !== ''"
                  text>
                <a target="_blank" style="color:black; text-decoration: none;"
                   :href="pageData.linkedInUrl">
                  <v-icon class="black--text">mdi-linkedin</v-icon>
                  LinkedIn
                </a>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-layout justify-center>
          <v-flex xs12 sm12 md12 lg8>
            <router-view/>
          </v-flex>
        </v-layout>
      </v-container>


    </v-main>
<!--    <v-layout style="height: 300px">-->
<!--      <v-img src="/images/background.png" contain></v-img>-->
<!--    </v-layout>-->
<!--    <div class="hidden-sm-and-up" style="height: 500px"></div>-->
    <div style="height: 250px"></div>
    <v-footer
        dark
        height="auto"
        app
        absolute
        inset
        style="margin-bottom: 0 !important;background-color: #2D2D2D;"
    >
      <v-card class="flex" flat tile>
        <div v-if="pageData">
          <v-card-title v-if="pageData.messengerUrl !== ''" class="py-1" style="background-color: #2D2D2D;">
            <v-spacer></v-spacer>
            <div class="mr-2 white--text font-weight-regular heading">Reach us on</div>
            <v-btn
                class="white black--text toolbar-item ml-2"
                dark
                rounded
                target="_blank" :href="pageData.messengerUrl">
              <img class="pr-1" style="height: 25px" src="https://firebasestorage.googleapis.com/v0/b/trooperquotes.appspot.com/o/logos%2F580b57fcd9996e24bc43c526.png?alt=media&amp;token=8e7a8975-ac35-4af7-a254-adfeca032706" alt=""></img>
              Messenger
            </v-btn
            >
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
        </div>


        <!--Footer Lower Section-->
        <v-responsive class="fill-height">
          <v-container fluid style="background-color: #2D2D2D;">
            <v-card-text style="background-color: #2D2D2D;">

              <v-container class="my-0 py-0">
                <v-layout row wrap align-center>
                  <!--Company Logo-->
                  <v-flex  v-if="pageData" class="mb-3"  xs12 sm6 md4 lg4>
                    <div  v-if="pageData.toolbarLogo !== ''" class="mt-1">
                      <a href="/">
                        <img :src="pageData.toolbarLogo" height="43px" width="auto"/></a>
                    </div>
                  </v-flex>
                  <!--Contact icons Section-->
                  <v-col v-if="pageData">
                    <v-flex xs12 sm12 md12 lg4 >
                      <v-layout
                          v-for="(supportOption, index) in pageData.supportOptions"
                          :key="index"
                          class="mb-2 support-section"
                      >
                        <div>
                          <v-icon class="mt-2">{{ supportOption.icon }}</v-icon>
                        </div>
                        <div class="ml-2">
                          <v-layout>
                            <h4 class="font-weight-thin mt-3">
                              {{ supportOption.title }}
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h5 class="font-weight-thin">
                              {{ supportOption.subtitle }}
                            </h5>
                          </v-layout>
                        </div>
                      </v-layout>
                    </v-flex>
                  </v-col>
                  <!--Connect with Social Media Section-->
                  <v-col
                      v-if="pageData"
                      flat
                      class="d-flex justify-lg-end justify-md-end justify-sm-center"
                      xs4 sm4 md4 lg4>
                    <v-btn fab small>
                      <a target="_blank" style="color:white"
                         :href="pageData.igUrl">
                        <v-icon>mdi-instagram</v-icon>
                      </a>
                    </v-btn>
                    <v-btn fab small>
                      <a target="_blank" style="color:white"
                         :href="pageData.fbUrl">
                        <v-icon>mdi-facebook</v-icon>
                      </a>
                    </v-btn>
                    <v-btn fab small>
                      <a target="_blank" style="color:white"
                         :href="pageData.xUrl">
                        <v-icon>mdi-twitter</v-icon>
                      </a>
                    </v-btn>
                    <v-btn fab small>
                      <a target="_blank" style="color:white"
                         :href="pageData.linkedInUrl">
                        <v-icon>mdi-linkedin</v-icon>
                      </a>
                    </v-btn>
                  </v-col>
                  <!--pages section-->
                  <v-flex v-if="pageData" xs12 sm12 md12 lg4 class="mb-3">
                    <!--<p class="mb-2"><b>Pages</b></p>-->
                    <div v-for="(pageLink, index) in pageData.pageLinks" :key="index">
                      <a
                          class="my-0"
                          target="_blank"
                          style="color:white;text-decoration:none"
                          :href="pageLink.route"
                      >
                        {{ pageLink.title }}
                      </a>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>

            </v-card-text>
          </v-container>
        </v-responsive>


        <v-card-actions v-if="pageData" class="justify-center" style="background-color: #2D2D2D;">
          <h5 class="font-weight-thin">
            {{pageData.footerNote}}
          </h5>
        </v-card-actions>
      </v-card>
    </v-footer>
    <v-dialog v-model="phoneDialog"
              max-width="650"
    >
      <v-card v-if="pageData">
        <v-card-title v-if="pageData.phoneNumber">
          Call or Text
        </v-card-title>
        <v-card-text class="pa-4 display-1">
          {{pageData.phoneNumber}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              block
              text
              @click="phoneDialog = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

import {collection, doc, setDoc, onSnapshot} from "firebase/firestore";

export default {
  data() {
    return {
      pageData: null,
      phoneDialog: false,
      centerSupportSection: false
    }
  },
  created() {
    let db = this.$store.state.db
    let pageRef = doc(db, 'pageContent', 'troopermovesMain')
    onSnapshot(pageRef, res => {
      if (res.exists) {
        let data = res.data()
        this.pageData = data
        this.$store.dispatch('setPageData', data)
        document.title = data.title
      }
    })

  },
  methods: {

  }
};
// let pageData = {
//   footerNote: 'Copyright ©2024 Troopermoves Inc . All Right Reserved.',
//   pageLinks: [
//     { title: "Terms and Conditions", route: "/terms" },
//     { title: "Privacy", route: "/privacy" },
//     { title: "FAQ", route: "/faq" }
//   ],
//   supportOptions: [
//     {
//       icon: "phone",
//       title: "647-424-5422"
//     },
//     {
//       icon: "email",
//       title: "clients@troopermoves.com"
//     }
//   ],
//   title: 'Trooper Moves Inc',
//   toolbarLogo: 'https://firebasestorage.googleapis.com/v0/b/troopermoves-vue2-cms.appspot.com/o/pageContent%2FtroopermovesMain%2Flogo.png?alt=media&token=0e267636-c6fd-4eb3-ab4b-c9abf679c98b',
//   phoneNumber: '(647) 424-5422',
//   blogUrl: 'https://blog.troopermoves.com',
//   igUrl: 'https://www.instagram.com/troopermoves/',
//   fbUrl: 'https://www.facebook.com/Troopermoves/',
//   messengerUrl: 'http://m.me/Troopermoves',
//   xUrl: 'https://twitter.com/troopermoves',
//   linkedInUrl: 'https://www.linkedin.com/company/trooper-moves/?'
// }
// this.pageData = pageData

// let homeData = {
//   title: 'Trooper Moves Inc',
//   headerImage: 'https://firebasestorage.googleapis.com/v0/b/troopermoves-vue2-cms.appspot.com/o/pageContent%2FtroopermovesMain%2Flanding.png?alt=media&token=613ad928-d468-4e1a-a713-55a5321a645d',
//   actionButtonLink: 'https://order.flexline.app/c986cc2f-419c-4e75-b5c1-4b5264acc198/Fy9TydPe3oTA2w0t3n8b',
//   actionButtonTitle: 'Get Estimate',
//   buttonStyle: 'background:linear-gradient(135deg, #FF57B9 0%,#A704FD 100%)',
//   headerTitle: 'Simpler way to move',
//   headerSubtitle: ' Every move is unique. Home, office or furniture pickup, no matter the size, we\'ve got you covered!\n',
//   // buttonStyle: 'background:linear-gradient(135deg, #13f1fc 0%,#0470dc 100%);'
//   actionSteps: [
//     {icon: 'location_on', title: 'Enter locations'},
//     {icon: 'local_shipping', title: 'Choose move or delivery'},
//     {icon: 'fact_check', title: 'Review and book'}
//   ],
//   aboutUsTitle: 'Here\'s how we help',
//   aboutUsSubtitle: 'We move your stuff from one place to another, the best way possible and at a surprisingly fair rate.',
//   marketServedImg: 'https://firebasestorage.googleapis.com/v0/b/troopermoves-vue2-cms.appspot.com/o/pageContent%2FtroopermovesMain%2Fdeliverying.png?alt=media&token=52b9133a-d5ce-4c86-8d49-6931ab210c77',
//   marketServedTitle: 'Our service providers',
//   marketServedSubtitle: 'We are a network of experienced movers. Based in Toronto and helping everyone move anywhere within the Greater Toronto Area. Indipendent, strong and proud of serving this great city we call home.',
//   serviceOfferTitle: 'Our Clients',
//   serviceOfferSubtitle: 'Residents, real estate agents, and furniture retailers all across the GTA have trusted us over the years. We are confident you will too.',
//   serviceOfferImg: 'https://firebasestorage.googleapis.com/v0/b/troopermoves-vue2-cms.appspot.com/o/pageContent%2FtroopermovesMain%2Fpeople.png?alt=media&token=63a9df62-270f-4b79-bd57-7e1e02cd7fc1',
//   blogUrl: 'https://blog.troopermoves.com',
//   reviewsTitle: 'Our reviews',
//   reviews: [
//     {
//       text:
//           "Great customer service to start with, easy to get in contact with. Also a smooth seemless process.Enjoyed the quote configurator and payment process. In just a few clicks the things I wanted moved was moved with just their app! Great digital platform, would definetly use again and recommending for sure!",
//       reviewedBy: "Tamin R.",
//       rating: 5
//     },
//     {
//       text:
//           "Fantastic service and very professional. Assembly/disassembly of my furniture was very convenient. Would recommend Trooper without hesitation!",
//       reviewedBy: "Lia D.",
//       rating: 5
//     },
//     {
//       text:
//           "Very Good customer service and easily understood difficult situation of moving and assembling furniture's..",
//       reviewedBy: "Denzal M.",
//       rating: 5
//     },
//     {
//       text: "They did a very professional job of fixing up a bunk bed, a single bed and a dining table. Would defo recommend.",
//       reviewedBy: "Owais Q.",
//       rating: 5
//     }
//   ],
//   bottomBackgroundImg: 'https://firebasestorage.googleapis.com/v0/b/troopermoves-vue2-cms.appspot.com/o/pageContent%2FtroopermovesMain%2Fbackground.png?alt=media&token=8d3b1045-e244-4cbc-96a3-2da346acff2c'
// }
// let template = {...pageData, ...homeData}

// let templateObj1 = {
//   footerNote: '',
//   pageLinks: [
//     { title: "Terms and Conditions", route: "/terms" },
//     { title: "Privacy", route: "/privacy" },
//     { title: "FAQ", route: "/faq" }
//   ],
//   supportOptions: [
//     {
//       icon: "phone",
//       title: ""
//     },
//     {
//       icon: "email",
//       title: ""
//     }
//   ],
//   title: '',
//   toolbarLogo: '',
//   phoneNumber: '',
//   blogUrl: '',
//   igUrl: '',
//   fbUrl: '',
//   messengerUrl: '',
//   xUrl: '',
//   linkedInUrl: ''
// }
// let templateObj2 = {
//   title: '',
//   headerImage: '',
//   actionButtonLink: '',
//   actionButtonTitle: '',
//   buttonStyle: 'background:linear-gradient(135deg, #FF57B9 0%,#A704FD 100%)',
//   headerTitle: '',
//   headerSubtitle: '',
//   // buttonStyle: 'background:linear-gradient(135deg, #13f1fc 0%,#0470dc 100%);'
//   actionSteps: [
//     {icon: 'location_on', title: ''},
//   ],
//   aboutUsTitle: '',
//   aboutUsSubtitle: '',
//   marketServedImg:'',
//   marketServedTitle: 'Our service providers',
//   marketServedSubtitle: '',
//   serviceOfferTitle: '',
//   serviceOfferSubtitle: '',
//   serviceOfferImg: '',
//   blogUrl: '',
//   reviewsTitle: '',
//   reviews: [
//     {
//       text: "",
//       reviewedBy: "",
//       rating: 5
//     },
//   ],
//   bottomBackgroundImg: ''}
// let template = {...templateObj1, ...templateObj2}

// let template = {...templateObj1, ...templateObj2}
// let templateObj1 = {
//   footerNote: '',
//   pageLinks: [
//     { title: "Terms and Conditions", route: "/terms" },
//     { title: "Privacy", route: "/privacy" },
//     { title: "FAQ", route: "/faq" }
//   ],
//   supportOptions: [
//     {
//       icon: "phone",
//       title: ""
//     },
//     {
//       icon: "email",
//       title: ""
//     }
//   ],
//   title: '',
//   toolbarLogo: '',
//   phoneNumber: '',
//   blogUrl: '',
//   igUrl: '',
//   fbUrl: '',
//   messengerUrl: '',
//   xUrl: '',
//   linkedInUrl: ''
// }
// let templateObj2 = {
//   title: '',
//   headerImage: '',
//   actionButtonLink: '',
//   actionButtonTitle: '',
//   buttonStyle: 'background:linear-gradient(135deg, #FF57B9 0%,#A704FD 100%)',
//   headerTitle: '',
//   headerSubtitle: '',
//   // buttonStyle: 'background:linear-gradient(135deg, #13f1fc 0%,#0470dc 100%);'
//   actionSteps: [
//     {icon: 'location_on', title: ''},
//   ],
//   aboutUsTitle: '',
//   aboutUsSubtitle: '',
//   marketServedImg:'',
//   marketServedTitle: 'Our service providers',
//   marketServedSubtitle: '',
//   serviceOfferTitle: '',
//   serviceOfferSubtitle: '',
//   serviceOfferImg: '',
//   blogUrl: '',
//   reviewsTitle: '',
//   reviews: [
//     {
//       text: "",
//       reviewedBy: "",
//       rating: 5
//     },
//   ],
//   bottomBackgroundImg: ''}
</script>
