import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"column":""}},[_c(VResponsive,{staticClass:"text-center fill-height"},[_c(VContainer,[_c(VLayout,{attrs:{"align-center":"","justify-center":"","row":"","wrap":""}},_vm._l((_vm.listingOptions),function(option){return _c(VFlex,{key:option.title,staticClass:"pa-3",attrs:{"xs12":"","sm12":"","md6":"","lg6":""}},[_c(VCard,{on:{"click":function($event){_vm.goTolisting (option)}}},[(option.titleColour === 'black')?_c(VImg,{staticClass:"black--text align-center",attrs:{"src":option.imgUrl,"height":"200px"}},[_c(VCardTitle,{domProps:{"textContent":_vm._s(option.title)}})],1):_vm._e(),(option.titleColour === 'white')?_c(VImg,{staticClass:"white--text align-center",attrs:{"src":option.imgUrl,"height":"200px"}},[_c(VCardTitle,{domProps:{"textContent":_vm._s(option.title)}})],1):_vm._e()],1)],1)}),1)],1)],1),_c('div',{staticClass:"hidden-sm-and-up",staticStyle:{"height":"150px"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }