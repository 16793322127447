import Vue from 'vue'
import VueRouter from 'vue-router'
import messagingView from '../views/MessagingUi.vue'
import home from '../views/Home.vue'
import options from '../views/Options.vue'
import VueGtag from "vue-gtag"

Vue.use(VueRouter)
Vue.use(VueGtag, {
  config: { id: "G-3H1BTW9FTV" }
});

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/options',
    name: 'options',
    component: options
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
