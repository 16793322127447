import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height"},[_c(VResponsive,{staticClass:"text-center fill-height"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VFlex,{attrs:{"xs12":"","sm6":"","offset-sm3":""}},[_c(VCardTitle,[_vm._v("Natural Language Processing Bot")]),_c(VTextarea,{attrs:{"outlined":"","label":"New Text"},model:{value:(_vm.textMessage),callback:function ($$v) {_vm.textMessage=$$v},expression:"textMessage"}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"blue white--text",on:{"click":_vm.sendText}},[_vm._v("send")])],1)],1),(_vm.selectedConvos.length !== 0)?_c(VExpansionPanels,_vm._l((_vm.selectedConvos),function(item,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,[(item.direction === 'incoming')?_c('div',[_c(VIcon,{staticClass:"success--text"},[_vm._v("call_received")])],1):_vm._e(),(item.direction === 'outgoing')?_c('div',[_c(VIcon,{staticClass:"blue--text"},[_vm._v("call_made")])],1):_vm._e(),_c(VContainer,[_c('div',[_vm._v(_vm._s(item.text))])])],1),_c(VExpansionPanelContent,[(item.dialogFlowAgent)?_c(VLayout):_vm._e(),(!item.dialogFlowAgent)?_c(VLayout,[_c(VCardText,[_c(VBtn,{staticClass:"blue--text",attrs:{"disabled":"","text":"","outlined":"","rounded":""}},[_vm._v("add date "),_c(VIcon,[_vm._v("event")])],1),_c(VBtn,{staticClass:"blue--text",attrs:{"disabled":"","text":"","outlined":"","rounded":""}},[_c(VIcon,[_vm._v("timer")]),_vm._v("Add time")],1)],1)],1):_vm._e(),_c(VLayout,[(!_vm.loading)?_c(VCardText,[_c('h6',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(_vm.moment(item.created))+" ")])]):_vm._e()],1)],1)],1)}),1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }