const firebaseConfig = {
    apiKey: "AIzaSyDgvXmPbx90km81CjXXYSWWkotdgWOU0t4",
    authDomain: "troopermoves-vue2-cms.firebaseapp.com",
    projectId: "troopermoves-vue2-cms",
    storageBucket: "troopermoves-vue2-cms.appspot.com",
    messagingSenderId: "903832534049",
    appId: "1:903832534049:web:e6c9ab85470e801c227760",
    measurementId: "G-3H1BTW9FTV"
};
export default firebaseConfig
